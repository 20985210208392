import React from "react";
import Link from "next/link";
import { Typography } from "@mui/material";
import { useAppSelector } from "lib/redux";
import { Button } from "components/shared";
import { Section, SectionTitle } from "components/shared";
import { selectMainHomeContent } from "./store/contentSlice";

interface CardProps {
  title: string;
  content: string;
  button: string;
  icon: string;
  link: string;
}

const Card: React.FC<CardProps> = ({ title, content, button, icon, link = "#" }) => {
  return (
    <div className="main-container w-full max-w-[421px] relative mx-auto my-0 p-4 sm:p-6">
      <div className="w-full h-full bg-white rounded-lg shadow-md p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <h3 className="text-[20px] font-bold text-[#02010d]">{title}</h3>
          <p className="text-[18px] font-normal text-[#02010d]">{content}</p>
        </div>
        <div className={`flex w-full ${button ? "justify-between" : "justify-end"} items-center`}>
          {button && (
            <Button
              className="w-fit py-4 sm:mt-8"
              LinkComponent={Link}
              href={link}
              size="large"
              variant="contained"
            >
              {button}
            </Button>
          )}
          {icon && <img src={icon} alt={title} className="w-20 h-20" />}
        </div>
      </div>
    </div>
  );
};


const HowItWorksSection: React.FC = () => {
  const {
    howItWorksSection: {
      title,
      subtitle,
      button: { text, path },
    },
  } = useAppSelector(selectMainHomeContent);

  const items = [
    {
      title: "Learn real estate for free",
      content:
        "BrickMaster is coming—a free and fun way to master the real estate market!",
      button: "",
      icon: "/icon_1.png",
      link: "/buyer-owner/tools",
    },
    {
      title: "Top real estate experts",
      content:
        "From staging your home to securing your mortgage, find your perfect fit.",
      button: "Learn more",
      icon: "/icon_2.png",
      link: "/our-experts",
    },
    {
      title: "Top tradies",
      content:
        "From roofers to cleaners, connect with skilled tradies to get the job done right.",
      button: "Learn more",
      icon: "/icon_3.png",
      link: "/our-tradies",
    },
  ];

  return (
    <Section className="-mb-0.5 bg-background-main">
      <div className="container flex flex-col gap-12 sm:gap-16">
        <header className="flex-center mx-auto max-w-screen-xs flex-col gap-10 text-center text-light">
          <SectionTitle className="max-w-screen-xs text-pretty" variant="h2">
            {title || "What do you need?"}
          </SectionTitle>
          <Typography className="text-pretty" variant="p1">
            {subtitle}
          </Typography>
        </header>

        <div className="grid grid-cols-1 gap-6 text-light sm:grid-cols-2 lg:grid-cols-3 lg:gap-10">
          {items.map(({ title, content, button, icon, link }, index) => (
            <Card
              key={index}
              title={title}
              content={content}
              button={button}
              icon={icon}
              link={link}
            />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default HowItWorksSection;